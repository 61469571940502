<template>
  <div class="animated fadeIn">
    <b-card>
      <b-card-body>
        <b-row>
          <b-col>
            <!-- <filtering-panel ref="filteringPanel" :hideSearchButton="true" :filters="filteringPanel.filters" @change="onChange" @search="searchData"></filtering-panel> -->

            <filtering-panel
              ref="filteringPanel"
              mode="server"
              dataset-name="reimbursements"
              :show-labels="false"
              :hide-custom-filters="true"
              :filters="filteringPanel.filters"
              :loaded="filteringPanel.loaded"
              @search="searchData"
              @change="onChange"
              :load-dictionaries="loadDictionaries"
              @dict-loaded="onFilteringPanelLoad"
              @state-changed="onFilteringPanelStateChange"
            />
          </b-col>
        </b-row>

        <hr />
        <b-row align-h="between">
          <b-col lg="1">
            <b-button-group>
              <b-button
                variant="outline-dark"
                title="Add Reimbursement"
                @click="addItem()"
              >
                <font-awesome-icon icon="plus" />
              </b-button>
            </b-button-group>
          </b-col>
        </b-row>

        <hr />
        <b-row v-if="isLoading">
          <b-col>
            <div class="text-center">
              <b-spinner variant="light" />
            </div>
          </b-col>
        </b-row>
        <b-row v-if="!isLoading">
          <b-col>
            <div
              v-for="(reportData, index) in usersData"
              :key="`${uid}-${index}`"
            >
              <b-alert show class="dark-alert">
                {{ reportData.user.name }}
              </b-alert>

              <div
                v-for="(week, index2) in reportData.weeks"
                :key="`${uid}-${index2}`"
              >
                <b-card header-bg-variant="transparent">
                  <h5 v-b-toggle="'collapse-day'" style="cursor: pointer">
                    Period: {{ week.period }}
                  </h5>

                  <b-collapse
                    :id="'collapse-day'"
                    accordion="my-accordion"
                    class="mt-2"
                  >
                    <b-card>
                      <b-table
                        :fields="dayTotalsColumns"
                        :items="week.details"
                        hover
                        @row-clicked="expandDetails"
                        style="cursor:pointer"
                      >
                        <template #cell(reimbursementtotal)="data">
                          ${{ data.item.reimbursementtotal.toFixed(2) }}
                        </template>

                        <template #cell(reimbursementpayaddition)="data">
                          ${{ data.item.reimbursementpayaddition.toFixed(2) }}
                        </template>

                        <template slot="row-details" slot-scope="row">
                          <b-card>
                            <div
                              v-for="(day, index3) in row.item.details"
                              :key="`${uid}-${index3}`"
                            >
                              <b-row>
                                <b-col lg="1" @click="viewItem(day.id)">
                                  <div>Date: {{ day.track_date }}</div>
                                  <div>ID: {{ day.id }}</div>
                                </b-col>
                                <b-col @click="viewItem(day.id)">
                                  <div>
                                    Menu Placement: {{ day.rmbsales_mp }}
                                  </div>
                                  <div>
                                    New Accounts: {{ day.rmbsales_acc }}
                                  </div>
                                  <div>
                                    Premier Accounts: {{ day.rmbsales_premacc }}
                                  </div>
                                  <div>Incentives: {{ day.rmbsales }}</div>
                                </b-col>
                                <b-col @click="viewItem(day.id)">
                                  <div>
                                    Over 1 Case: {{ day.rmbadditions_overone }}
                                  </div>
                                  <div>
                                    Suburban Tasting:
                                    {{ day.rmbadditions_suburban }}
                                  </div>
                                  <div>
                                    Tasting Addition: {{ day.rmbadditions }}
                                  </div>
                                </b-col>
                                <b-col @click="viewItem(day.id)">
                                  <div>
                                    Tolls / Parking: {{ day.rmbtollsparking }}
                                  </div>
                                  <div>Mileage: {{ day.rmbmileage }}</div>
                                  <div>Rate: {{ day.rmbmileagerate }}</div>
                                  <div>
                                    Mileage Calc: {{ day.rmbmileagecalc }}
                                  </div>
                                  <div>Total Auto: {{ day.rmbtotalauto }}</div>
                                </b-col>
                                <b-col @click="viewItem(day.id)">
                                  <div>Expenses: {{ day.rmbexpenses }}</div>
                                  <div>
                                    Accommodation: {{ day.rmbaccommodation }}
                                  </div>
                                </b-col>
                                <b-col lg="2" @click="viewItem(day.id)">
                                  Notes: {{ day.Notes }}
                                </b-col>
                                <b-col lg="3">
                                  Files:
                                  <div
                                    v-for="(f, index4) in day.files"
                                    :key="`${uid}-${index4}`"
                                  >
                                    {{ f.name }}
                                    <b-button
                                      class="m-1"
                                      variant="success"
                                      size="sm"
                                      @click="downloadItem(f)"
                                    >
                                      <font-awesome-icon icon="download" />
                                    </b-button>
                                  </div>
                                </b-col>
                              </b-row>
                              <hr />
                              <div>
                                <strong>Reimbursements:</strong> ${{
                                  day.reimbursementtotal
                                }}
                                <strong>Pay Addition:</strong> ${{
                                  day.reimbursementpayaddition
                                }}
                              </div>
                              <hr />
                            </div>
                          </b-card>
                        </template>
                        <!--
                                        <template v-slot:cell()="data">
                                            {{ data.day }}
                                        </template>
-->
                      </b-table>
                    </b-card>
                  </b-collapse>
                </b-card>
                <b-card bg-variant="light">
                  <b-card-text>
                    <h6>
                      Total Reimbursement: ${{
                        week.totalReimbursement.toFixed(2)
                      }}
                    </h6>
                    <h6>
                      Total Pay Additions: ${{
                        week.totalPayAdditions.toFixed(2)
                      }}
                    </h6>
                  </b-card-text>
                </b-card>
              </div>
            </div>

            <b-card bg-variant="light">
              <b-card-text>
                <h4>
                  Total Reimbursement: ${{
                    reportTotals.reimbursement.toFixed(2)
                  }}
                </h4>
                <h4>
                  Total Pay Additions: ${{
                    reportTotals.payAdditions.toFixed(2)
                  }}
                </h4>
              </b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import axios from "axios";
import Moment from "moment";
import { extendMoment } from "moment-range";
const moment = extendMoment(Moment);

import FilteringPanel from "@/components/FilteringPanel";

import { mapState } from "vuex";

export default {
  components: {
    FilteringPanel
  },
  data: function() {
    return {
      mode: "",
      isLoading: false,
      rawData: {},
      filteringPanel: {
        loaded: false,
        selected: {},
        filters: [
          {
            type: "select",
            dataType: "string",
            title: "User Name",
            name: "user_name",
            trackby: "id",
            label: "label",
            options: [],
            selected: {}
          },
          {
            type: "daterange",
            dataType: "datetime",
            defaultRange: "This quarter",
            title: "Period",
            name: "period"
          }
        ]
      },
      usersData: [],

      dayTotalsColumns: [
        {
          key: "track_date",
          label: "Date"
        },
        {
          key: "reimbursementtotal",
          label: "Reimbursements"
        },
        {
          key: "reimbursementpayaddition",
          label: "Pay addition"
        }
      ],
      showDayDetails: false,
      reportTotals: {
        reimbursement: 0,
        payAdditions: 0
      }
    };
  },
  computed: {
    ...mapState({
      profile: state => state.profile
    })
  },
  created() {
    if (this.$route.fullPath === "/reimbursements/my-reimbursements") {
      this.mode = "myreimbursements";
    } else {
      this.mode = "reimbursements";
    }

    if (this.mode === "myreimbursements") {
      //remove users filter
      this.filteringPanel.filters = this.filteringPanel.filters.filter(
        f => f.name != "user_name"
      );
    }
  },
  mounted() {
    if (this.startDate && this.endDate) {
      this.$refs["filteringPanel"].setValue("period", "daterange", {
        startDate: this.startDate,
        endDate: this.endDate
      });
    }

    /*
        if (this.mode === 'reimbursements') {
            let payload = {
                active: true
            }

            api.post('users', payload).then(users => {

                this.filteringPanel.filters.find(f => f.name === 'user_name').options = [...users.map(u => ({
                    id: u.id,
                    name: u.Name
                }))]
            })
        }
*/

    this.showDayDetails = true;

    // this.searchData()
  },
  methods: {
    async loadDictionaries() {
      let self = this;

      const users = async () => {
        if (this.filteringPanel.filters.find(f => f.name === "user_name")) {
          self.$api.get("dictionaries/users").then(response => {
            this.filteringPanel.filters.find(
              f => f.name === "user_name"
            ).options = [
              ...response.map(u => ({
                id: u.id,
                label: u.full_name
              }))
            ];
          });
        }
      };

      Promise.all([users()]).then(() => {
        self.filteringPanel.loaded = true;
      });
    },
    onFilteringPanelLoad() {
      this.searchData();
    },
    onFilteringPanelStateChange() {},
    addItem: function() {
      this.$router.push({
        name: "Reimbursement submission",
        params: {
          action: "create"
        }
      });
    },
    viewItem: function(id) {
      this.$router.push({
        name: "Reimbursement submission",
        params: {
          action: "view",
          id: id
        }
      });
    },
    downloadItem: function(file) {
      axios({
        url: file.url,
        method: "GET",
        responseType: "blob" // important
      })
        .then(response => {
          const blob = new Blob([response.data], {
            type: response.data.type
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          //const contentDisposition = response.headers['content-disposition'];
          let fileName = file.name;

          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          link.remove();
          window.URL.revokeObjectURL(url);
        })
        .catch(response => {
          console.log(response);

          this.$form.makeToastError(response.message);
        });
    },
    expandDetails(row) {
      /*
                        this._showDayDetails = !this._showDayDetails

                        this.usersData.forEach(a => {

                            a.weeks.forEach(b => {

                                b.details.forEach(c => {

                                    c.details.forEach(d => {

                                        d._showDetails = this._showDayDetails

                                    })
                                })
                            })
                        })
            */
      row._showDetails = !row._showDetails;
    },
    onChange(e) {
      this.filteringPanel.selected = e;

      if (this.filteringPanel.selected["period"].changed === true) {
        this.filteringPanel.selected["period"].changed = false;

        this.searchData();
      } else {
        this.drawFullReport();
      }
    },
    drawFullReport() {
      let self = this;

      if (!this.rawData.length) return;

      self.usersData = [];
      self.reportTotals = {
        reimbursement: 0,
        payAdditions: 0
      };

      let userId = 0;

      if (this.mode === "myreimbursements") {
        userId = this.profile.data.id;
      }
      if (this.mode === "reimbursements") {
        userId = this.filteringPanel.selected["user_name"]
          ? this.filteringPanel.selected["user_name"].id
          : 0;
      }

      let data =
        userId > 0
          ? self.rawData.filter(s => s.user_id == userId.toString())
          : this.rawData;

      let users = self.$helpers.getDistinctArray(
        data,
        "user_id",
        "user_name",
        "id",
        "name"
      );

      users.forEach(u => {
        let p = self.rawData.filter(d => d.user_id == u.id);

        let payload = {
          user: u,
          details: p
        };

        this.drawUserReport(payload);
      });
    },
    searchData: function() {
      let self = this;

      this.getData().then(response => {
        self.rawData = response;

        self.drawFullReport();
      });
    },
    async getData() {
      let self = this;

      //let url = 'reimbursements'
      //if (this.mode === 'myreimbursements') url = 'reimbursements/my'

      let data = {
        period: this.$refs["filteringPanel"].selected["period"]
      };

      self.isLoading = true;
      //20201202 await
      let result = await self.$api.post("reimbursements", data);
      self.isLoading = false;

      return result;
    },
    getCalendar() {
      let minDate = moment(
        this.$refs["filteringPanel"].selected["period"].startDate
      ).isoWeekday(1);
      let maxDate = moment(
        this.$refs["filteringPanel"].selected["period"].endDate
      ).isoWeekday(7);

      let range = moment.range(minDate, maxDate);
      let weeks = [];
      for (let mday of range.by("days")) {
        if (weeks.indexOf(mday.isoWeek()) === -1) {
          weeks.push(mday.isoWeek());
        }
      }

      let calendar = [];
      let date = minDate.clone();

      for (let index = 0; index < weeks.length; index++) {
        let weeknumber = weeks[index];

        let firstWeekDay = moment
          .utc(date)
          .day("Monday")
          .isoWeek(weeknumber);
        let lastWeekDay = moment
          .utc(date)
          .day("Sunday")
          .isoWeek(weeknumber);

        date = date.add(1, "week");

        let weekRange = moment.range(firstWeekDay, lastWeekDay);
        calendar.push(weekRange);
      }

      return calendar;
    },
    drawUserReport(payload) {
      let reportData = {
        user: payload.user,
        weeks: []
      };

      reportData.weeks = [];

      let calendar = this.getCalendar();

      for (let week of calendar) {
        let details = payload.details.filter(
          i =>
            moment(i.track_date).isSameOrAfter(week.start) &&
            moment(i.track_date).isSameOrBefore(week.end)
        );

        let weekData = {
          week_num: week.start.isoWeek(),
          visible: false,
          period: `${week.start.format("LL")} - ${week.end.format(
            "LL"
          )}, Week ${week.start.isoWeek()}`,
          totalReimbursement: 0,
          totalPayAdditions: 0,
          details: []
        };

        let days = this.processWeekDetails(details);

        weekData.details = [...weekData.details, ...days];

        if (weekData.details.length > 0) {
          weekData.totalReimbursement = weekData.details.sum(
            "reimbursementtotal"
          );
          weekData.totalPayAdditions = weekData.details.sum(
            "reimbursementpayaddition"
          );

          this.reportTotals.reimbursement += weekData.totalReimbursement;
          this.reportTotals.payAdditions += weekData.totalPayAdditions;

          reportData.weeks.push(weekData);
        }
      }

      if (reportData.weeks.length > 0) this.usersData.push(reportData);
    },
    processWeekDetails(details) {
      let result = [];

      for (let day of details) {
        // day.files = [{id: 1,name: 'test', url: 'http://google.com'}]

        day.files = this.processFiles(day);

        let d = result.find(r => r.track_date == day.track_date);

        //if date not exitst
        if (!d) {
          d = {};

          d.track_date = day.track_date;
          d.reimbursementtotal = parseFloat(day.reimbursementtotal || 0);
          d.reimbursementpayaddition = parseFloat(
            day.reimbursementpayaddition || 0
          );

          d._showDetails = false;
          d.details = [];
          d.details.push(day);

          result.push(d);
        } else {
          d.reimbursementtotal += parseFloat(day.reimbursementtotal || 0);
          d.reimbursementpayaddition += parseFloat(
            day.reimbursementpayaddition || 0
          );
          d.details.push(day);
        }
      }

      return result;
    },
    processFiles(day) {
      let result = [];

      let files = day["file_ids"] ? day["file_ids"].split("|") : [];

      files.forEach(f => {
        let a = f.split(";");
        let file = {};
        file.url = process.env.VUE_APP_API_URL + "/files/" + a[0];
        file.name = a[1];
        file.type = a[2];
        result.push(file);
      });

      return result;
    }
  },
  watch: {}
};
</script>

<style scoped>
.dark-alert {
  color: #fff;
  background-color: #2f353a;
}
</style>
