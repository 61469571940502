var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn"
  }, [_c('b-card', [_c('b-card-body', [_c('b-row', [_c('b-col', [_c('filtering-panel', {
    ref: "filteringPanel",
    attrs: {
      "mode": "server",
      "dataset-name": "reimbursements",
      "show-labels": false,
      "hide-custom-filters": true,
      "filters": _vm.filteringPanel.filters,
      "loaded": _vm.filteringPanel.loaded,
      "load-dictionaries": _vm.loadDictionaries
    },
    on: {
      "search": _vm.searchData,
      "change": _vm.onChange,
      "dict-loaded": _vm.onFilteringPanelLoad,
      "state-changed": _vm.onFilteringPanelStateChange
    }
  })], 1)], 1), _c('hr'), _c('b-row', {
    attrs: {
      "align-h": "between"
    }
  }, [_c('b-col', {
    attrs: {
      "lg": "1"
    }
  }, [_c('b-button-group', [_c('b-button', {
    attrs: {
      "variant": "outline-dark",
      "title": "Add Reimbursement"
    },
    on: {
      "click": function click($event) {
        return _vm.addItem();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "plus"
    }
  })], 1)], 1)], 1)], 1), _c('hr'), _vm.isLoading ? _c('b-row', [_c('b-col', [_c('div', {
    staticClass: "text-center"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "light"
    }
  })], 1)])], 1) : _vm._e(), !_vm.isLoading ? _c('b-row', [_c('b-col', [_vm._l(_vm.usersData, function (reportData, index) {
    return _c('div', {
      key: "".concat(_vm.uid, "-").concat(index)
    }, [_c('b-alert', {
      staticClass: "dark-alert",
      attrs: {
        "show": ""
      }
    }, [_vm._v(" " + _vm._s(reportData.user.name) + " ")]), _vm._l(reportData.weeks, function (week, index2) {
      return _c('div', {
        key: "".concat(_vm.uid, "-").concat(index2)
      }, [_c('b-card', {
        attrs: {
          "header-bg-variant": "transparent"
        }
      }, [_c('h5', {
        directives: [{
          name: "b-toggle",
          rawName: "v-b-toggle",
          value: 'collapse-day',
          expression: "'collapse-day'"
        }],
        staticStyle: {
          "cursor": "pointer"
        }
      }, [_vm._v(" Period: " + _vm._s(week.period) + " ")]), _c('b-collapse', {
        staticClass: "mt-2",
        attrs: {
          "id": 'collapse-day',
          "accordion": "my-accordion"
        }
      }, [_c('b-card', [_c('b-table', {
        staticStyle: {
          "cursor": "pointer"
        },
        attrs: {
          "fields": _vm.dayTotalsColumns,
          "items": week.details,
          "hover": ""
        },
        on: {
          "row-clicked": _vm.expandDetails
        },
        scopedSlots: _vm._u([{
          key: "cell(reimbursementtotal)",
          fn: function fn(data) {
            return [_vm._v(" $" + _vm._s(data.item.reimbursementtotal.toFixed(2)) + " ")];
          }
        }, {
          key: "cell(reimbursementpayaddition)",
          fn: function fn(data) {
            return [_vm._v(" $" + _vm._s(data.item.reimbursementpayaddition.toFixed(2)) + " ")];
          }
        }, {
          key: "row-details",
          fn: function fn(row) {
            return [_c('b-card', _vm._l(row.item.details, function (day, index3) {
              return _c('div', {
                key: "".concat(_vm.uid, "-").concat(index3)
              }, [_c('b-row', [_c('b-col', {
                attrs: {
                  "lg": "1"
                },
                on: {
                  "click": function click($event) {
                    return _vm.viewItem(day.id);
                  }
                }
              }, [_c('div', [_vm._v("Date: " + _vm._s(day.track_date))]), _c('div', [_vm._v("ID: " + _vm._s(day.id))])]), _c('b-col', {
                on: {
                  "click": function click($event) {
                    return _vm.viewItem(day.id);
                  }
                }
              }, [_c('div', [_vm._v(" Menu Placement: " + _vm._s(day.rmbsales_mp) + " ")]), _c('div', [_vm._v(" New Accounts: " + _vm._s(day.rmbsales_acc) + " ")]), _c('div', [_vm._v(" Premier Accounts: " + _vm._s(day.rmbsales_premacc) + " ")]), _c('div', [_vm._v("Incentives: " + _vm._s(day.rmbsales))])]), _c('b-col', {
                on: {
                  "click": function click($event) {
                    return _vm.viewItem(day.id);
                  }
                }
              }, [_c('div', [_vm._v(" Over 1 Case: " + _vm._s(day.rmbadditions_overone) + " ")]), _c('div', [_vm._v(" Suburban Tasting: " + _vm._s(day.rmbadditions_suburban) + " ")]), _c('div', [_vm._v(" Tasting Addition: " + _vm._s(day.rmbadditions) + " ")])]), _c('b-col', {
                on: {
                  "click": function click($event) {
                    return _vm.viewItem(day.id);
                  }
                }
              }, [_c('div', [_vm._v(" Tolls / Parking: " + _vm._s(day.rmbtollsparking) + " ")]), _c('div', [_vm._v("Mileage: " + _vm._s(day.rmbmileage))]), _c('div', [_vm._v("Rate: " + _vm._s(day.rmbmileagerate))]), _c('div', [_vm._v(" Mileage Calc: " + _vm._s(day.rmbmileagecalc) + " ")]), _c('div', [_vm._v("Total Auto: " + _vm._s(day.rmbtotalauto))])]), _c('b-col', {
                on: {
                  "click": function click($event) {
                    return _vm.viewItem(day.id);
                  }
                }
              }, [_c('div', [_vm._v("Expenses: " + _vm._s(day.rmbexpenses))]), _c('div', [_vm._v(" Accommodation: " + _vm._s(day.rmbaccommodation) + " ")])]), _c('b-col', {
                attrs: {
                  "lg": "2"
                },
                on: {
                  "click": function click($event) {
                    return _vm.viewItem(day.id);
                  }
                }
              }, [_vm._v(" Notes: " + _vm._s(day.Notes) + " ")]), _c('b-col', {
                attrs: {
                  "lg": "3"
                }
              }, [_vm._v(" Files: "), _vm._l(day.files, function (f, index4) {
                return _c('div', {
                  key: "".concat(_vm.uid, "-").concat(index4)
                }, [_vm._v(" " + _vm._s(f.name) + " "), _c('b-button', {
                  staticClass: "m-1",
                  attrs: {
                    "variant": "success",
                    "size": "sm"
                  },
                  on: {
                    "click": function click($event) {
                      return _vm.downloadItem(f);
                    }
                  }
                }, [_c('font-awesome-icon', {
                  attrs: {
                    "icon": "download"
                  }
                })], 1)], 1);
              })], 2)], 1), _c('hr'), _c('div', [_c('strong', [_vm._v("Reimbursements:")]), _vm._v(" $" + _vm._s(day.reimbursementtotal) + " "), _c('strong', [_vm._v("Pay Addition:")]), _vm._v(" $" + _vm._s(day.reimbursementpayaddition) + " ")]), _c('hr')], 1);
            }), 0)];
          }
        }], null, true)
      })], 1)], 1)], 1), _c('b-card', {
        attrs: {
          "bg-variant": "light"
        }
      }, [_c('b-card-text', [_c('h6', [_vm._v(" Total Reimbursement: $" + _vm._s(week.totalReimbursement.toFixed(2)) + " ")]), _c('h6', [_vm._v(" Total Pay Additions: $" + _vm._s(week.totalPayAdditions.toFixed(2)) + " ")])])], 1)], 1);
    })], 2);
  }), _c('b-card', {
    attrs: {
      "bg-variant": "light"
    }
  }, [_c('b-card-text', [_c('h4', [_vm._v(" Total Reimbursement: $" + _vm._s(_vm.reportTotals.reimbursement.toFixed(2)) + " ")]), _c('h4', [_vm._v(" Total Pay Additions: $" + _vm._s(_vm.reportTotals.payAdditions.toFixed(2)) + " ")])])], 1)], 2)], 1) : _vm._e()], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }